import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - La sueur des autres?" />
    <h3 className='underline-title'>La sueur des autres?</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Tu gagneras ton pain à la sueur de ton front (dixit Magister)<br />
        Ton front sue-t-il vraiment?<br />
        Pour acquérir tes richesses? <br />
        Combien de fûts de sueur ton front a-t-il remplis?<br />
      </p>
      <p>
        Est-il vrai qu'avec la meme divine sueur<br />
        Opères-tu tant de miracles d'opulence?<br />
        Avec ce si haut niveau d'abondance?<br />
        Ton front a dû donce couler l'Amazone de sueur!<br />
      </p>
      <p>
        Une chose étonne:<br />
        Tu as d'énormes richesses<br />
        Ce que tu sues sans arret?<br />
        Pourquoi n'es-tu pas déjà déshydraté?<br /> 
      </p>
      <p>
        Seraient-elles les sueurs des fronts des autres?<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
